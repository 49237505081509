<template>
  <div class="usercont">
    <div class="user-title">绑定手机</div>
    <div class="info-cell">
      <p>手机号码</p>
      <span>{{ user && encryptPhone }}</span>
      <span class="icon"><svg-icon icon-class="edit" @click="isPopupShow = true"></svg-icon></span>
    </div>

    <popup-form v-show="isPopupShow && step === 1" @close="isPopupShow = false">
      <template #title>
        <div class="title-tabs">
          <span
            class="pop-title"
            @click="isPhone = true"
            :class="[isPhone ? 'pop-title-active' : null]"
            >验证原手机</span
          >
          <span class="v-line" v-if="user && user.email"></span>
          <span
            v-if="user && user.email"
            class="pop-title"
            @click="isPhone = false"
            :class="[!isPhone ? 'pop-title-active' : null]"
            >验证邮箱</span
          >
        </div>
      </template>
      <template #form>
        <portal-form
          v-show="isPhone"
          type="phone"
          :fnType="2"
          @close="isPopupShow = false"
          @next="handleNext"
        />
        <portal-form
          v-show="!isPhone"
          type="email"
          :fnType="3"
          @close="isPopupShow = false"
          @next="handleNext"
        />
      </template>
    </popup-form>

    <popup-form v-show="isPopupShow && step === 2" @close="handleClose" title="验证新手机">
      <template #form>
        <portal-form
          :userId="userId"
          type="phone"
          :fnType="1"
          @close="handleClose"
          @ok="handleClose"
        />
      </template>
    </popup-form>
  </div>
</template>

<script>
import userMixin from "@/mixins/user-mixin.js";
import PopupForm from "@/components/popup-form/Index.vue";
import PortalForm from "@/components/portal-form/Index.vue";

export default {
  components: {
    PopupForm,
    PortalForm
  },
  mixins: [userMixin],
  data() {
    return {
      userId: "",
      isPopupShow: false,
      isPhone: true,
      step: 1 // 1-验证原手机/邮箱  2-验证新手机
    };
  },
  methods: {
    handleNext(id) {
      this.step = 2;
      this.userId = id;
      console.log(id);
    },
    handleClose() {
      this.isPopupShow = false;
      this.isPhone = true;
      this.step = 1;
    }
  }
};
</script>

<style lang="less" scoped>
.title-tabs {
  display: flex;
  align-items: center;
  .pop-title {
    display: block;
    height: 26px;
    color: #86909c;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    cursor: pointer;
  }
  .pop-title-active {
    color: #111c34;
  }
  .v-line {
    display: block;
    width: 0;
    height: 18px;
    margin: 0 16px;
    border-right: 1px solid #e5e6eb;
  }
}
</style>
